import { render, staticRenderFns } from "./LaybarePlusEgift.vue?vue&type=template&id=44ba7511&scoped=true"
var script = {}
import style0 from "./LaybarePlusEgift.vue?vue&type=style&index=0&id=44ba7511&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44ba7511",
  null
  
)

export default component.exports